<template>
  <div class="">
    <Settings />
    <div
      class="mt-5 flex flex-col-reverse lg:flex-row lg:justify-between px-4 md:px-8 xl:px-24 xxl:max-w-screen-xxl xxl:m-auto 2xl:px-0"
    >
      <!-- Uploaded documents -->
      <div
        class="mt-6 lg:m-0 border border-border pt-4 px-4 pb-6 rounded w-full"
      >
        <h6 class="title text-title">Uploaded Documents</h6>
        <p class="text-sec-text text-sm leading-4 mt-1">
          Below are a list of uploaded documents required for succesful loan
          applications.
        </p>
        <div class="border-b border-input mt-4 pb-1">
          <span
            @click="() => uploadedDocumentType('individual')"
            :class="
              documentView === 'individual'
                ? 'text-primary border-b-3 border-primary px-2'
                : 'text-sec-text'
            "
            class="title text-xs pb-1 cursor-pointer"
            >Individual</span
          >
          <span
            @click="() => uploadedDocumentType('business')"
            :class="
              documentView === 'business'
                ? 'text-primary border-b-3 border-primary px-2'
                : 'text-sec-text'
            "
            class="title pb-1 text-xs ml-8 cursor-pointer"
            >Business</span
          >
        </div>

        <!-- Individual Uploaded Documents -->
        <div v-if="documentView === 'individual'" class="mt-5">
          <div
            v-if="individualUploadedDocuments.length > 0"
            class="grid lg:grid-cols-2 xxl:grid-cols-3 gap-3 lg:gap-10"
          >
            <div
              v-for="(upDoc, i) in individualUploadedDocuments"
              :key="i"
              class="rounded-lg border border-border lg:h-60 lg:flex lg:flex-col lg:justify-between"
            >
              <!-- <label :for="upDoc.id" class="cursor-pointer"> -->
              <div
                class="flex justify-between px-4 py-3 lg:h-28 border-b border-border bg-white rounded-t-lg"
              >
                <div class="w-">
                  <h6 class="title text-sm">{{ upDoc.type.name }}</h6>
                  <p class="text-xs mt-2">{{ upDoc.type.description }}</p>
                </div>
                <span
                  @click.prevent="() => showDeleteDocumentModal(upDoc, i)"
                  class="self-end cursor-pointer"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="#ff0000"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0)">
                      <path
                        d="M2 5V15C2 15.55 2.45 16 3 16H12C12.55 16 13 15.55 13 15V5H2ZM5 14H4V7H5V14ZM7 14H6V7H7V14ZM9 14H8V7H9V14ZM11 14H10V7H11V14Z"
                        fill="#ff8f8f"
                      />
                      <path
                        d="M13.25 2H10V0.75C9.99921 0.551331 9.91994 0.361024 9.77946 0.220543C9.63898 0.0800612 9.44867 0.000789946 9.25 0L5.75 0C5.55133 0.000789946 5.36102 0.0800612 5.22054 0.220543C5.08006 0.361024 5.00079 0.551331 5 0.75V2H1.75C1.55125 2.00053 1.36079 2.07971 1.22025 2.22025C1.07971 2.36079 1.00053 2.55125 1 2.75V4H14V2.75C13.9995 2.55125 13.9203 2.36079 13.7797 2.22025C13.6392 2.07971 13.4488 2.00053 13.25 2ZM9 2H6V1.013H9V2Z"
                        fill="#ff8f8f"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </div>
              <!-- </label> -->
              <div
                class="px-4 py-3 flex lg:flex-col lg:justify-between items-center lg:h-32 bg-input rounded-b-lg"
              >
                <span class="">
                  <svg
                    class="w-9 lg:w-14 h-9 lg:h-14"
                    width="37"
                    height="37"
                    viewBox="0 0 37 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="18.5" cy="18.5" r="18.5" fill="white" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11 27.223C11 28.2048 11.7966 29 12.7786 29H24.777C25.7584 29 26.5556 28.2046 26.5556 27.223V14.7761C26.5556 14.2957 26.3192 13.7285 25.977 13.3894L22.123 9.57005C21.7827 9.23283 21.2176 9 20.7383 9H12.7788C11.798 9 11 9.79547 11 10.777V27.223ZM12.7788 9.88889C12.2888 9.88889 11.8889 10.2865 11.8889 10.777V27.223C11.8889 27.7136 12.2872 28.1111 12.7786 28.1111H24.777C25.2677 28.1111 25.6667 27.7135 25.6667 27.223V14.7787H21.8858C21.1505 14.7787 20.5547 14.183 20.5547 13.4475V9.88889H12.7788ZM25.3203 13.8898H21.8858C21.6413 13.8898 21.4436 13.6921 21.4436 13.4475V10.068L25.3203 13.8898ZM22.5616 17.826C22.8071 17.826 23.0061 17.627 23.0061 17.3816C23.0061 17.1361 22.8071 16.9371 22.5616 16.9371H14.7839C14.5384 16.9371 14.3394 17.1361 14.3394 17.3816C14.3394 17.627 14.5384 17.826 14.7839 17.826H22.5616ZM19.0061 14.7816C19.2515 14.7816 19.4505 14.5826 19.4505 14.3371C19.4505 14.0917 19.2515 13.8927 19.0061 13.8927H14.7839C14.5384 13.8927 14.3394 14.0917 14.3394 14.3371C14.3394 14.5826 14.5384 14.7816 14.7839 14.7816H19.0061ZM22.5616 20.9371C22.8071 20.9371 23.0061 20.7381 23.0061 20.4927C23.0061 20.2472 22.8071 20.0482 22.5616 20.0482H14.7839C14.5384 20.0482 14.3394 20.2472 14.3394 20.4927C14.3394 20.7381 14.5384 20.9371 14.7839 20.9371H22.5616ZM22.5616 24.0482C22.8071 24.0482 23.0061 23.8493 23.0061 23.6038C23.0061 23.3583 22.8071 23.1594 22.5616 23.1594H14.7839C14.5384 23.1594 14.3394 23.3583 14.3394 23.6038C14.3394 23.8493 14.5384 24.0482 14.7839 24.0482H22.5616Z"
                      fill="#1656C1"
                    />
                  </svg>
                </span>
                <div
                  @click="viewUploadedDocuemnt(upDoc.id)"
                  class="w-full px-2 overflow-x-hidden text-sec-text text-xs ml-6 lg:m-0 rounded bg-white flex items-center justify-center h-6 cursor-pointer underline"
                >
                  View {{ documentName(upDoc.name) }}
                </div>
              </div>
            </div>
          </div>

          <div v-else class="flex flex-col items-center justify-center w-full">
            <span class="">
              <svg
                width="144"
                height="144"
                viewBox="0 0 144 144"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M126 48V125.958C126.006 126.746 125.856 127.527 125.559 128.257C125.263 128.987 124.826 129.652 124.272 130.213C123.719 130.774 123.061 131.221 122.335 131.527C121.609 131.834 120.83 131.995 120.042 132H23.958C22.3789 132 20.8643 131.373 19.7472 130.257C18.63 129.141 18.0016 127.627 18 126.048V17.952C18 14.73 20.694 12 24.012 12H89.982L126 48ZM114 54H84V24H30V120H114V54ZM48 42H66V54H48V42ZM48 66H96V78H48V66ZM48 90H96V102H48V90Z"
                  fill="#575A65"
                  fill-opacity="0.4"
                />
              </svg>
            </span>
            <span class="max-w-md text-center">
              It’s quite empty here, please select and upload documents to
              improve the quality of your loan applications.
            </span>
          </div>
        </div>

        <!-- Business Uploaded Documents -->
        <div v-if="documentView === 'business'" class="mt-5">
          <div
            v-if="businessUploadedDocuments.length > 0"
            class="grid lg:grid-cols-2 xxl:grid-cols-3 gap-3 lg:gap-10"
          >
            <div
              v-for="(upDocB, i) in businessUploadedDocuments"
              :key="i"
              class="rounded-lg border border-border lg:h-60 lg:flex lg:flex-col lg:justify-between"
            >
              <!-- <label :for="upDocB.id" class="cursor-pointer"> -->
              <div
                class="flex justify-between px-4 py-3 lg:h-28 border-b border-border bg-white rounded-t-lg"
              >
                <div class="w-">
                  <h6 class="title text-sm">{{ upDocB.type.name }}</h6>
                  <p class="text-xs mt-2">{{ upDocB.type.description }}</p>
                </div>
                <span
                  @click="() => showDeleteDocumentModal(upDocB, i)"
                  class="self-end cursor-pointer"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0)">
                      <path
                        d="M2 5V15C2 15.55 2.45 16 3 16H12C12.55 16 13 15.55 13 15V5H2ZM5 14H4V7H5V14ZM7 14H6V7H7V14ZM9 14H8V7H9V14ZM11 14H10V7H11V14Z"
                        fill="#ff8f8f"
                      />
                      <path
                        d="M13.25 2H10V0.75C9.99921 0.551331 9.91994 0.361024 9.77946 0.220543C9.63898 0.0800612 9.44867 0.000789946 9.25 0L5.75 0C5.55133 0.000789946 5.36102 0.0800612 5.22054 0.220543C5.08006 0.361024 5.00079 0.551331 5 0.75V2H1.75C1.55125 2.00053 1.36079 2.07971 1.22025 2.22025C1.07971 2.36079 1.00053 2.55125 1 2.75V4H14V2.75C13.9995 2.55125 13.9203 2.36079 13.7797 2.22025C13.6392 2.07971 13.4488 2.00053 13.25 2ZM9 2H6V1.013H9V2Z"
                        fill="#ff8f8f"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </div>
              <!-- <input type="file" :id="upDocB.id" class="hidden" /> -->
              <!-- </label> -->
              <div
                class="px-4 py-3 flex lg:flex-col lg:justify-between items-center lg:h-32 bg-input rounded-b-lg"
              >
                <span class="">
                  <svg
                    class="w-9 lg:w-14 h-9 lg:h-14"
                    width="37"
                    height="37"
                    viewBox="0 0 37 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="18.5" cy="18.5" r="18.5" fill="white" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11 27.223C11 28.2048 11.7966 29 12.7786 29H24.777C25.7584 29 26.5556 28.2046 26.5556 27.223V14.7761C26.5556 14.2957 26.3192 13.7285 25.977 13.3894L22.123 9.57005C21.7827 9.23283 21.2176 9 20.7383 9H12.7788C11.798 9 11 9.79547 11 10.777V27.223ZM12.7788 9.88889C12.2888 9.88889 11.8889 10.2865 11.8889 10.777V27.223C11.8889 27.7136 12.2872 28.1111 12.7786 28.1111H24.777C25.2677 28.1111 25.6667 27.7135 25.6667 27.223V14.7787H21.8858C21.1505 14.7787 20.5547 14.183 20.5547 13.4475V9.88889H12.7788ZM25.3203 13.8898H21.8858C21.6413 13.8898 21.4436 13.6921 21.4436 13.4475V10.068L25.3203 13.8898ZM22.5616 17.826C22.8071 17.826 23.0061 17.627 23.0061 17.3816C23.0061 17.1361 22.8071 16.9371 22.5616 16.9371H14.7839C14.5384 16.9371 14.3394 17.1361 14.3394 17.3816C14.3394 17.627 14.5384 17.826 14.7839 17.826H22.5616ZM19.0061 14.7816C19.2515 14.7816 19.4505 14.5826 19.4505 14.3371C19.4505 14.0917 19.2515 13.8927 19.0061 13.8927H14.7839C14.5384 13.8927 14.3394 14.0917 14.3394 14.3371C14.3394 14.5826 14.5384 14.7816 14.7839 14.7816H19.0061ZM22.5616 20.9371C22.8071 20.9371 23.0061 20.7381 23.0061 20.4927C23.0061 20.2472 22.8071 20.0482 22.5616 20.0482H14.7839C14.5384 20.0482 14.3394 20.2472 14.3394 20.4927C14.3394 20.7381 14.5384 20.9371 14.7839 20.9371H22.5616ZM22.5616 24.0482C22.8071 24.0482 23.0061 23.8493 23.0061 23.6038C23.0061 23.3583 22.8071 23.1594 22.5616 23.1594H14.7839C14.5384 23.1594 14.3394 23.3583 14.3394 23.6038C14.3394 23.8493 14.5384 24.0482 14.7839 24.0482H22.5616Z"
                      fill="#1656C1"
                    />
                  </svg>
                </span>
                <div
                  @click="viewUploadedDocuemnt(upDocB.id)"
                  class="w-full px-2 overflow-x-hidden text-sec-text text-xs ml-6 lg:m-0 rounded bg-white flex items-center justify-center h-6 cursor-pointer underline"
                >
                  View {{ documentName(upDocB.name) }}
                </div>
              </div>
            </div>
          </div>

          <div v-else class="flex flex-col items-center justify-center w-full">
            <span class="">
              <svg
                width="144"
                height="144"
                viewBox="0 0 144 144"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M126 48V125.958C126.006 126.746 125.856 127.527 125.559 128.257C125.263 128.987 124.826 129.652 124.272 130.213C123.719 130.774 123.061 131.221 122.335 131.527C121.609 131.834 120.83 131.995 120.042 132H23.958C22.3789 132 20.8643 131.373 19.7472 130.257C18.63 129.141 18.0016 127.627 18 126.048V17.952C18 14.73 20.694 12 24.012 12H89.982L126 48ZM114 54H84V24H30V120H114V54ZM48 42H66V54H48V42ZM48 66H96V78H48V66ZM48 90H96V102H48V90Z"
                  fill="#575A65"
                  fill-opacity="0.4"
                />
              </svg>
            </span>
            <span class="max-w-md text-center">
              It’s quite empty here, please select and upload documents to
              improve the quality of your loan applications.
            </span>
          </div>
        </div>
      </div>

      <!-- Uploading documents -->
      <div
        class="border border-active pt-4 px-4 pb-6 rounded h-max lg:min-w-110 lg:max-w-110 lg:ml-6"
      >
        <h6 class="title text-title">Documents yet to be uploaded</h6>
        <p class="text-sec-text text-sm leading-4 mt-1">
          Below are a list of documents that may be required for a seamless and
          successful loan application.
        </p>
        <div class="border-b border-input mt-4 pb-1">
          <span
            @click="documentType('individual')"
            :class="
              documents.individual
                ? 'text-primary border-b-3 border-primary px-2'
                : 'text-sec-text'
            "
            class="title text-xs pb-1 cursor-pointer"
            >Individual</span
          >
          <span
            @click="documentType('business')"
            :class="
              documents.business
                ? 'text-primary border-b-3 border-primary px-2'
                : 'text-sec-text'
            "
            class="title pb-1 text-xs ml-8 cursor-pointer"
            >Business</span
          >
        </div>

        <!-- Individual Documents -->
        <div v-if="documents.individual" class="mt-5 grid gap-3">
          <div
            @click.prevent="showUploadModal(doc.id, doc.name)"
            v-for="(doc, i) in individualDocsPending"
            :key="i"
            class="cursor-pointer"
          >
            <span class="hidden lg:inline-block title text-sm">{{
              doc.name
            }}</span>
            <!-- <label :for="doc.id" class="cursor-pointer"> -->
            <div
              class="px-4 flex lg:flex-row-reverse items-center justify-between lg:justify-center border border-upload-border border-dashed rounded h-9 lg:h-12 bg-upload"
            >
              <span class="lg:ml-2 hidden lg:block text-sm text-sec-text"
                >Upload Document</span
              >
              <span class="lg:ml-2 lg:hidden underline text-sm text-sec-text">{{
                doc.name
              }}</span>
              <span class="">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.99993 7.39083L10.8286 10.2188L9.88526 11.1622L8.6666 9.9435V13.6668H7.33326V9.94216L6.1146 11.1622L5.17126 10.2188L7.99993 7.39083ZM7.99993 0.333496C9.14458 0.333551 10.2493 0.754239 11.1041 1.51558C11.9588 2.27691 12.504 3.32581 12.6359 4.46283C13.4654 4.68904 14.1891 5.19957 14.6804 5.90518C15.1717 6.6108 15.3994 7.46662 15.3237 8.32309C15.248 9.17956 14.8737 9.98219 14.2663 10.5907C13.6589 11.1992 12.8569 11.5749 12.0006 11.6522V10.3095C12.3074 10.2657 12.6024 10.1612 12.8683 10.0021C13.1343 9.84309 13.3659 9.63265 13.5496 9.3831C13.7334 9.13356 13.8656 8.84991 13.9385 8.54872C14.0114 8.24753 14.0236 7.93483 13.9744 7.62888C13.9252 7.32292 13.8155 7.02983 13.6517 6.76673C13.488 6.50363 13.2735 6.27577 13.0207 6.09648C12.768 5.91718 12.482 5.79002 12.1796 5.72244C11.8772 5.65485 11.5643 5.64818 11.2593 5.70283C11.3637 5.21675 11.358 4.71348 11.2427 4.22986C11.1275 3.74625 10.9055 3.29455 10.593 2.90785C10.2805 2.52115 9.88553 2.20923 9.43691 1.99496C8.98829 1.78068 8.49743 1.66947 8.00026 1.66947C7.5031 1.66947 7.01223 1.78068 6.56361 1.99496C6.115 2.20923 5.71999 2.52115 5.40753 2.90785C5.09507 3.29455 4.87307 3.74625 4.75778 4.22986C4.6425 4.71348 4.63686 5.21675 4.74126 5.70283C4.13303 5.58861 3.50434 5.72069 2.99349 6.07C2.48264 6.41932 2.13148 6.95727 2.01726 7.5655C1.90304 8.17373 2.03512 8.80242 2.38444 9.31327C2.73376 9.82412 3.2717 10.1753 3.87993 10.2895L3.99993 10.3095V11.6522C3.14356 11.575 2.34153 11.1994 1.73402 10.591C1.1265 9.98249 0.752147 9.17987 0.676379 8.32338C0.60061 7.46689 0.828249 6.61102 1.31951 5.90535C1.81077 5.19967 2.5344 4.68909 3.36393 4.46283C3.49573 3.32575 4.04087 2.27677 4.89565 1.51541C5.75043 0.754043 6.85523 0.333405 7.99993 0.333496Z"
                    fill="#575A65"
                  />
                </svg>
              </span>
            </div>
            <!-- </label> -->
          </div>
          <div
            v-if="individualUploadedDocuments.length === 6"
            class="flex flex-col items-center justify-center w-full"
          >
            <span class="">
              <svg
                width="80"
                height="80"
                viewBox="0 0 144 144"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="72"
                  cy="72"
                  r="72"
                  fill="#1656C1"
                  fill-opacity="0.08"
                />
                <circle
                  cx="72"
                  cy="72"
                  r="56"
                  fill="#1656C1"
                  fill-opacity="0.20"
                />
                <circle
                  cx="72"
                  cy="72"
                  r="40"
                  fill="#1656C1"
                  fill-opacity="0.50"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M54.5321 70.7144C53.6859 71.4244 53.5756 72.6859 54.2856 73.5321L64.8998 86.1816C65.3214 86.684 65.9374 86.9271 66.5454 86.893C66.5537 86.8925 66.5619 86.892 66.5702 86.8914C66.9784 86.8638 67.3818 86.7112 67.7192 86.4281L89.797 67.9026C90.6431 67.1926 90.7535 65.9311 90.0435 65.0849L88.8474 63.6595C88.1374 62.8133 86.8759 62.7029 86.0297 63.413L66.6091 79.7088L58.4759 70.016C57.7659 69.1698 56.5044 69.0595 55.6582 69.7695L54.5321 70.7144Z"
                  fill="white"
                />
              </svg>
            </span>
            <span
              class="max-w-md text-center text-xs tracking-widest mx-20 mt-4"
            >
              You have uploaded all the required documents for this category.
            </span>
          </div>
        </div>

        <!-- Business Documents -->
        <div v-if="documents.business" class="mt-5 grid gap-3">
          <div
            @click.prevent="showUploadModal(docB.id, docB.name)"
            v-for="(docB, i) in businessDocsPending"
            :key="i"
            class="cursor-pointer"
          >
            <span class="hidden lg:inline-block title text-sm">{{
              docB.name
            }}</span>
            <!-- <label :for="docB.id" class="cursor-pointer"> -->
            <div
              class="px-4 flex lg:flex-row-reverse items-center justify-between lg:justify-center border border-upload-border border-dashed rounded h-9 lg:h-12 bg-upload"
            >
              <span class="lg:ml-2 hidden lg:block text-sm text-sec-text"
                >Upload Document</span
              >
              <span class="lg:ml-2 lg:hidden underline text-sm text-sec-text">{{
                docB.name
              }}</span>
              <span class="">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.99993 7.39083L10.8286 10.2188L9.88526 11.1622L8.6666 9.9435V13.6668H7.33326V9.94216L6.1146 11.1622L5.17126 10.2188L7.99993 7.39083ZM7.99993 0.333496C9.14458 0.333551 10.2493 0.754239 11.1041 1.51558C11.9588 2.27691 12.504 3.32581 12.6359 4.46283C13.4654 4.68904 14.1891 5.19957 14.6804 5.90518C15.1717 6.6108 15.3994 7.46662 15.3237 8.32309C15.248 9.17956 14.8737 9.98219 14.2663 10.5907C13.6589 11.1992 12.8569 11.5749 12.0006 11.6522V10.3095C12.3074 10.2657 12.6024 10.1612 12.8683 10.0021C13.1343 9.84309 13.3659 9.63265 13.5496 9.3831C13.7334 9.13356 13.8656 8.84991 13.9385 8.54872C14.0114 8.24753 14.0236 7.93483 13.9744 7.62888C13.9252 7.32292 13.8155 7.02983 13.6517 6.76673C13.488 6.50363 13.2735 6.27577 13.0207 6.09648C12.768 5.91718 12.482 5.79002 12.1796 5.72244C11.8772 5.65485 11.5643 5.64818 11.2593 5.70283C11.3637 5.21675 11.358 4.71348 11.2427 4.22986C11.1275 3.74625 10.9055 3.29455 10.593 2.90785C10.2805 2.52115 9.88553 2.20923 9.43691 1.99496C8.98829 1.78068 8.49743 1.66947 8.00026 1.66947C7.5031 1.66947 7.01223 1.78068 6.56361 1.99496C6.115 2.20923 5.71999 2.52115 5.40753 2.90785C5.09507 3.29455 4.87307 3.74625 4.75778 4.22986C4.6425 4.71348 4.63686 5.21675 4.74126 5.70283C4.13303 5.58861 3.50434 5.72069 2.99349 6.07C2.48264 6.41932 2.13148 6.95727 2.01726 7.5655C1.90304 8.17373 2.03512 8.80242 2.38444 9.31327C2.73376 9.82412 3.2717 10.1753 3.87993 10.2895L3.99993 10.3095V11.6522C3.14356 11.575 2.34153 11.1994 1.73402 10.591C1.1265 9.98249 0.752147 9.17987 0.676379 8.32338C0.60061 7.46689 0.828249 6.61102 1.31951 5.90535C1.81077 5.19967 2.5344 4.68909 3.36393 4.46283C3.49573 3.32575 4.04087 2.27677 4.89565 1.51541C5.75043 0.754043 6.85523 0.333405 7.99993 0.333496Z"
                    fill="#575A65"
                  />
                </svg>
              </span>
            </div>
            <!-- <input :id="docB.id" type="file" class="hidden" /> -->
            <!-- </label> -->
          </div>
          <div
            v-if="businessUploadedDocuments.length === 4"
            class="flex flex-col items-center justify-center w-full"
          >
            <span class="">
              <svg
                width="80"
                height="80"
                viewBox="0 0 144 144"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="72"
                  cy="72"
                  r="72"
                  fill="#1656C1"
                  fill-opacity="0.08"
                />
                <circle
                  cx="72"
                  cy="72"
                  r="56"
                  fill="#1656C1"
                  fill-opacity="0.20"
                />
                <circle
                  cx="72"
                  cy="72"
                  r="40"
                  fill="#1656C1"
                  fill-opacity="0.50"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M54.5321 70.7144C53.6859 71.4244 53.5756 72.6859 54.2856 73.5321L64.8998 86.1816C65.3214 86.684 65.9374 86.9271 66.5454 86.893C66.5537 86.8925 66.5619 86.892 66.5702 86.8914C66.9784 86.8638 67.3818 86.7112 67.7192 86.4281L89.797 67.9026C90.6431 67.1926 90.7535 65.9311 90.0435 65.0849L88.8474 63.6595C88.1374 62.8133 86.8759 62.7029 86.0297 63.413L66.6091 79.7088L58.4759 70.016C57.7659 69.1698 56.5044 69.0595 55.6582 69.7695L54.5321 70.7144Z"
                  fill="white"
                />
              </svg>
            </span>
            <span
              class="max-w-md text-center text-xs tracking-widest mx-20 mt-4"
            >
              You have uploaded all the required documents for this category.
            </span>
          </div>
        </div>
      </div>

      <!-- Document upload modal -->
      <div
        @click.self="uploadModal = false"
        :class="uploadModal ? '' : 'hidden'"
        class="fixed bg-search-bg top-0 left-0 h-screen w-full flex justify-center overflow-y-scroll px-4"
      >
        <div
          class="bg-white w-full md:w-115 mt-25 rounded-lg p-5 md:p-10 h-max"
        >
          <h6 class="title text-6">{{ uploadBody.documentName }}</h6>
          <span class="mt-2 text-sec-text text-xs md:text-sm"
            >Please upload your {{ uploadBody.documentName }}.</span
          >

          <form
            action=""
            class="mt-10"
            id="upload-form"
            enctype="multipart/form-data"
          >
            <div class="">
              <label
                :for="uploadBody.documentId"
                class="text-title text-xs md:text-base title"
                >Please input the address on the document to be uploaded</label
              >
              <input
                autocomplete="off"
                type="text"
                class="block rounded-lg bg-main-bg w-full mt-2 px-2 md:px-4 py-1 md:py-3 h-14 border border-input doc-address"
                :id="uploadBody.documentId"
              />
            </div>
            <div class="mt-10">
              <span class="text-title text-xs md:text-base title"
                >Upload file</span
              >
              <div
                class="rounded-lg bg-main-bg w-full mt-2 px-2 md:px-4 py-4 md:py-8 min-h-max border border-dashed border-input flex flex-col items-center justify-center"
              >
                <span class="">
                  <svg
                    width="46"
                    height="40"
                    viewBox="0 0 46 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.9998 21.172L31.4858 29.656L28.6558 32.486L24.9998 28.83V40H20.9998V28.826L17.3438 32.486L14.5138 29.656L22.9998 21.172ZM22.9998 4.4432e-08C26.4338 0.00016354 29.7479 1.26223 32.3122 3.54624C34.8764 5.83025 36.512 8.97693 36.9078 12.388C39.3963 13.0666 41.5672 14.5982 43.0411 16.7151C44.515 18.8319 45.1981 21.3994 44.971 23.9688C44.744 26.5382 43.6212 28.9461 41.7989 30.7716C39.9766 32.5972 37.5708 33.7243 35.0018 33.956V29.928C35.9221 29.7966 36.8071 29.4831 37.605 29.0059C38.4028 28.5288 39.0977 27.8974 39.6489 27.1488C40.2002 26.4002 40.5967 25.5493 40.8155 24.6457C41.0343 23.7421 41.0709 22.804 40.9232 21.8861C40.7755 20.9683 40.4464 20.089 39.9552 19.2997C39.464 18.5104 38.8205 17.8268 38.0622 17.2889C37.3039 16.751 36.4461 16.3696 35.5388 16.1668C34.6315 15.9641 33.6929 15.9441 32.7778 16.108C33.091 14.6498 33.0741 13.1399 32.7282 11.6891C32.3824 10.2383 31.7164 8.88317 30.779 7.72306C29.8416 6.56295 28.6566 5.62721 27.3107 4.98439C25.9649 4.34157 24.4923 4.00794 23.0008 4.00794C21.5093 4.00794 20.0367 4.34157 18.6908 4.98439C17.345 5.62721 16.16 6.56295 15.2226 7.72306C14.2852 8.88317 13.6192 10.2383 13.2734 11.6891C12.9275 13.1399 12.9106 14.6498 13.2238 16.108C11.3991 15.7653 9.51302 16.1616 7.98048 17.2095C6.44793 18.2575 5.39445 19.8713 5.05179 21.696C4.70913 23.5207 5.10536 25.4068 6.15331 26.9393C7.20127 28.4719 8.8151 29.5253 10.6398 29.868L10.9998 29.928V33.956C8.43069 33.7247 6.0246 32.5978 4.20206 30.7724C2.37951 28.947 1.25644 26.5391 1.02914 23.9697C0.801831 21.4002 1.48475 18.8326 2.95853 16.7155C4.43231 14.5985 6.6032 13.0668 9.09179 12.388C9.4872 8.97675 11.1226 5.82982 13.6869 3.54573C16.2513 1.26164 19.5657 -0.000273405 22.9998 4.4432e-08Z"
                      fill="#575A65"
                      fill-opacity="0.4"
                    />
                  </svg>
                </span>
                <label
                  for="file-input"
                  class="cursor-pointer rounded mt-3 bg-primary bg-opacity-20 text-primary  overflow-x-hidden px-6 h-10 flex items-center justify-center title"
                >
                  {{ uploadButton }}
                  <input
                    @change="getDocument"
                    name="file"
                    id="file-input"
                    type="file"
                    class="hidden"
                    accept="image/jpeg, image/png, image/jpg, application/pdf"
                  />
                </label>
                <div class="mt-3 text-sec-text text-xs">
                  *Supported formats JPG, PNG, PDF
                  <span class="text-title title"> Max 5MB</span>
                </div>
              </div>
            </div>
            <div class="w-full mt-8">
              <button
                @click.prevent="uploadDocumentToApp"
                class="rounded bg-input w-full px-6 md:ml-auto block md:w-max h-14 title"
              >
                {{ isRequest ? "Uploading..." : "Submit" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- delete document modal -->
    <response-modal
      id="response"
      v-if="deleteModal && documentToBeDeleted"
      :wrapperClass="['fixed h-screen z-50 top-0 left-0 right-0 bg-sec-bg']"
      modalClass="modal-wrap mx-auto bg-white rounded-lg flex flex-col items-center justify-center max-w-xs border border-border mx-auto w-full p-6"
      contentClass="flex flex-col items-center justify-center w-full"
    >
      <div slot="alt-content" class="w-full">
        <div class="flex items-center flex-col">
          <h5
            class="text-lg text-text text-center font-simple text-gray-500 mt-2"
            style="max-width: 204px"
          >
            Are you sure you want to delete document?
          </h5>
        </div>
        <div class=""></div>
      </div>
      <button
        slot="cta"
        class="mt-6 py-3 bg-primary text-white w-full md:text-md text-sm rounded-lg no-focus font-extralight font-secondary tracking-wide"
        @click.prevent="deleteDocumentFromApp(documentToBeDeleted.id)"
      >
        Yes
      </button>
      <button
        slot="cta"
        class="mt-4 py-3 border border-border w-full text-sm rounded-lg no-focus text-primary bg-accent font-secondary font-normal"
        @click.prevent="
          deleteModal = false;
          documentToBeDeleted = null;
        "
      >
        No
      </button>
    </response-modal>
  </div>
</template>

<script>
import { BASE_API } from "../../constants";
import axios from "axios";
import { ApiInstance as api } from "../../utils";
import Settings from "../dashboard/Settings";
import ResponseModal from "../../components/partials/response-modal";

export default {
  name: "DocumentsSettings",
  components: {
    Settings,
    ResponseModal,
  },
  async mounted() {
    this.initAutoComplete();
    await this.getUploadedDocuments();
    await this.getDocumentTypes();
  },
  computed: {
    individualDocsPending() {
      const individualUploadedDocumentTypes = this.individualUploadedDocuments.map(
        (d) => d.type.id
      );
      return this.documentTypes.filter(
        (d) =>
          d.category === "individual" &&
          !individualUploadedDocumentTypes.includes(d.id)
      );
    },
    businessDocsPending() {
      const businessUploadedDocumentTypes = this.businessUploadedDocuments.map(
        (d) => d.type.id
      );
      const result = this.documentTypes.filter(
        (d) =>
          d.category === "business" &&
          !businessUploadedDocumentTypes.includes(d.id)
      );
      return result;
    },
  },
  data() {
    return {
      myFiles: ["index.html"],
      uploadModal: false,
      deleteModal: false,
      uploadButton: "Choose file",
      isRequest: false,
      uploadBody: {
        documentName: "",
        documentId: "",
        documentAddress: "",
        file: "",
      },
      documents: {
        individual: true,
        business: false,
      },
      documentView: "individual",
      uploadedDocuments: {
        individual: true,
        business: false,
      },
      individualUploadedDocuments: [],
      businessUploadedDocuments: [],
      individualToBeUploadedDocuments: [],
      businessToBeUploadedDocuments: [],
      toUpload: {
        individual: [],
        business: [],
      },
      uploaded: {
        individual: [],
        business: [],
      },
      documentToBeDeleted: null,
      documentTypes: [],
    };
  },
  methods: {
    async getDocumentTypes() {
      const response = await axios.get(BASE_API + "/documents/types/", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth-token"),
        },
      });
      this.documentTypes = response.data.data;
    },
    async getUploadedDocuments() {
      const response = await api.get("/documents");
      this.individualUploadedDocuments = response.data?.data?.filter(
        (e) => e.type?.category === "individual"
      );
      this.businessUploadedDocuments = response.data?.data?.filter(
        (e) => e.type?.category === "business"
      );
    },
    async viewUploadedDocuemnt(docId) {
      const response = await api.get(`/documents/view/${docId}`);
      window.open(response.data.url, "_blank");
    },
    showDeleteDocumentModal(doc) {
      this.deleteModal = true;
      this.documentToBeDeleted = doc;
    },
    async deleteDocumentFromApp(id) {
      this.deleteModal = false;

      if (this.documentView === "individual") {
        let index = this.individualUploadedDocuments?.findIndex(
          (i) => i.id === id
        );
        this.individualUploadedDocuments?.splice(index, 1);
      }
      if (this.documentView === "business") {
        let index = this.businessUploadedDocuments?.findIndex(
          (i) => i.id === id
        );
        this.businessUploadedDocuments?.splice(index, 1);
      }
      await api.post(`/documents/delete/`, { id });
    },
    documentType(arg) {
      this.documents[arg] = true;
      for (let i in this.documents) {
        if (i !== arg) {
          this.documents[i] = false;
        }
      }
    },
    uploadedDocumentType(arg) {
      this.documentView = arg;
    },
    showUploadModal(docId, docName) {
      this.uploadModal = true;
      this.uploadBody.documentId = docId;
      this.uploadBody.documentName = docName;
    },
    initAutoComplete() {
      // eslint-disable-next-line no-undef
      let autocomplete = new google.maps.places.Autocomplete(
        document.querySelector(".doc-address"),
        {
          componentRestrictions: { country: ["NG"] },
        }
      );

      autocomplete.addListener("place_changed", () => {
        var place = autocomplete.getPlace();
        this.uploadBody.documentAddress = place.formatted_address;
      });
    },
    getDocument(e) {
      this.uploadBody.file = e.target;
      // this.uploadButton = e.target.files[0].name.substring(0, 40)
      this.uploadButton = "Document attached";
    },
    uploadDocumentToApp() {
      if (this.uploadBody.documentAddress === "") {
        return this.$notie.alert({
          text: `Input address on the document.`,
          type: "error",
          time: 3,
          position: "top",
        });
      }
      if (this.uploadBody.file === "") {
        return this.$notie.alert({
          text: `Select document to upload.`,
          type: "error",
          time: 3,
          position: "top",
        });
      }
      this.isRequest = true;
      const formDocuments = document.getElementById("upload-form");
      let uploadForm = new FormData(formDocuments);
      uploadForm.append("address", this.uploadBody.documentAddress);
      uploadForm.append("typeId", this.uploadBody.documentId);

      api
        .post("/documents/upload/", uploadForm)
        .then((res) => {
          if (res.data.statusCode === 201) {
            this.isRequest = false;
            formDocuments.reset();
            this.uploadBody.documentAddress = "";
            this.uploadBody.documentId = "";
            this.uploadButton = "Choose file";
            this.$notie.alert({
              text: `Document upload successful.`,
              type: "success",
              time: 3,
              position: "top",
            });
            window.location.reload();
          }
        })
        .catch((e) => {
          this.$notie.alert({
            text: `Document upload failed. Please try again`,
            type: "error",
            time: 3,
            position: "top",
          });
          this.isRequest = false;
          return e;
        });
    },
    documentName(name) {
      return name
        .split("/")
        .slice(-1)
        .join("")
        .substring(0, 27);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-family: SohneKraftig, sans-serif;
}
</style>
