<template>
  <div :class="wrapperClass">
    <div class="backdrop absolute"></div>
    <div class="w-full absolute modal-content" :class="modalWrapperClass">
      <div :class="modalClass">
        <div :class="contentClass" class="relative">
        <slot name="cta-close"></slot>
          <slot name="modal-icon-success"></slot>
          <slot name="modal-icon-fail"></slot>
          <h5 class="text-center text-title font-primary text-2xl">
            {{ modalTitle }}
          </h5>
          <p class="text-sm text-text text-center font-simple mt-2">
            {{ modalText }}
          </p>
          <slot name="alt-content"></slot>
          <slot name="cta"></slot>
          <slot name="alt-cta"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Response",
    props: {
      wrapperClass: [String, Array],
      modalClass: String,
      contentClass: String,
      modalTitle: String,
      modalText: String,
      modalWrapperClass: String,
      showModal: Boolean,
    },
    watch: {
      showModal: function (val) {
        if (val) {
          document.querySelector("body").classList.add("no-overflow");
        } else document.querySelector("body").classList.remove("no-overflow");
      },
    },
    mounted() {
      if (this.showModal) {
        document.querySelector("body").classList.add("no-overflow");
      } else document.querySelector("body").classList.remove("no-overflow");
    },
  };
</script>

<style lang="scss" scoped>
.modal-content {
  // top: 35%;
  top: 10%;
  // left: 50%;
  // transform: translate(-50%, -50%);

  position: absolute;
  /* move the element half way across the screen */
  left: 50%;
  /* allow the width to be calculated dynamically */
  // width: auto;
  /* then move the element back again using a transform */
  transform: translateX(-50%);
}
</style>